"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTo = exports.stepIsDone = exports.checkTeamCreationStep = exports.checkUserOnbStep = exports.checkTeamOnbStep = exports.checkCompanyOnbStep = exports.getSteps = void 0;
var end2end_1 = require("@hudled/end2end");
var interfaces_1 = require("@hudled/interfaces");
var STEPS = {
    accountingAdmin: ['WELCOME_STEP', 'FIRST_CUSTOMER_CONNECTED', 'INVITE_TEAMMATES', 'ANSWER_FULL_ONBOARDING'],
    accountingNonAdmin: ['WELCOME_STEP', 'FIRST_CUSTOMER_CONNECTED'],
    owner: ['SET_ACCOUNTING', 'FILL_IN_THE_GAPS', 'TEAMS_CREATION', 'INVITE_TEAMMATES', 'REQUEST_SAAS_AUDIT', 'VISIT_DEALS'],
    other: ['WELCOME_STEP', 'FILL_IN_THE_GAPS', 'REQUEST_SAAS_AUDIT', 'VISIT_DEALS'],
};
var getSteps = function (profile, company) {
    if ((company === null || company === void 0 ? void 0 : company.isAccountingFirm) && profile.isAccountant) {
        if ((0, end2end_1.userCan)(interfaces_1.ACCESS_LEVEL.Admin)(profile)) {
            return STEPS.accountingAdmin;
        }
        return STEPS.accountingNonAdmin;
    }
    if (profile.accessLevel === interfaces_1.ACCESS_LEVEL.Owner) {
        return STEPS.owner;
    }
    return STEPS.other;
};
exports.getSteps = getSteps;
var checkCompanyOnbStep = function (company, onboardingStep) { var _a; return !!((_a = company === null || company === void 0 ? void 0 : company.completedOnboardingSteps) === null || _a === void 0 ? void 0 : _a.includes(onboardingStep)); };
exports.checkCompanyOnbStep = checkCompanyOnbStep;
var checkTeamOnbStep = function (team, onboardingStep) { var _a; return !!((_a = team === null || team === void 0 ? void 0 : team.completedOnboardingSteps) === null || _a === void 0 ? void 0 : _a.includes(onboardingStep)); };
exports.checkTeamOnbStep = checkTeamOnbStep;
var checkUserOnbStep = function (user, onboardingStep) { var _a; return !!((_a = user === null || user === void 0 ? void 0 : user.completedOnboardingSteps) === null || _a === void 0 ? void 0 : _a.includes(onboardingStep)); };
exports.checkUserOnbStep = checkUserOnbStep;
var checkTeamCreationStep = function (company, creationStep) {
    var _a;
    return (!!((_a = company === null || company === void 0 ? void 0 : company.teamCreationSteps) === null || _a === void 0 ? void 0 : _a.includes(creationStep)));
};
exports.checkTeamCreationStep = checkTeamCreationStep;
var stepIsDone = function (profile, company) { return function (stepName) { return ((0, exports.checkUserOnbStep)(profile, stepName) || (0, exports.checkCompanyOnbStep)(company, stepName)); }; };
exports.stepIsDone = stepIsDone;
var getTo = function (isDone, isDisabled, to, toWhenDone) {
    if (isDisabled)
        return undefined;
    if (isDone && toWhenDone)
        return toWhenDone;
    return to;
};
exports.getTo = getTo;
